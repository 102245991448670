import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../helpers/api/apiCall';
import { Endpoints } from '../../../utils/Endpoints';

const initialState = {
  discounts: [],
  loading: false,
  error: null
};

// Create discount
export const createDiscount = createAsyncThunk(
  'discount/create',
  async (discountData) => {
    const response = await client.post(Endpoints.DISCOUNT.CREATE, {
      name: discountData.code,
      isPercantage: discountData.discountType === 'percentage',
      amount: discountData.discountValue
    });
    return response.data.payload;
  }
);

// Delete discount
export const deleteDiscount = createAsyncThunk(
  'discount/delete',
  async (id) => {
    await client.delete(`${Endpoints.DISCOUNT.DELETE}/${id}`);
    return id;
  }
);

// Get all discounts
export const getDiscounts = createAsyncThunk(
  'discount/getAll',
  async () => {
    const response = await client.get(Endpoints.DISCOUNT.GET);
    return response.data.payload || [];
  }
);

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create discount
      .addCase(createDiscount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.discounts.push(action.payload);
      })
      .addCase(createDiscount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete discount
      .addCase(deleteDiscount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.discounts = state.discounts.filter(
          (discount) => discount.id !== action.payload
        );
      })
      .addCase(deleteDiscount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Get all discounts
      .addCase(getDiscounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDiscounts.fulfilled, (state, action) => {
        state.loading = false;
        state.discounts = action.payload || [];
      })
      .addCase(getDiscounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default discountSlice.reducer; 