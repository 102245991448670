import React, { useEffect } from 'react';
import { Table, Space, Tag } from 'antd';
import MainCard from '../../components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getExams } from '../../store/slices/exam';
import moment from 'moment';

const ExamList = () => {
  const dispatch = useDispatch();
  const { exams, loading } = useSelector((state) => state.exam);

  useEffect(() => {
    dispatch(getExams());
  }, [dispatch]);

  const getQuestionCount = (questionIds) => {
    if (!questionIds) return 0;
    return questionIds.split('-').filter(id => id).length;
  };

  const columns = [
    {
      title: 'Sınav Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Açıklama',
      dataIndex: 'examDescription',
      key: 'examDescription',
      ellipsis: true,
    },
    {
      title: 'Seviye',
      dataIndex: 'level',
      key: 'level',
      sorter: (a, b) => a.level - b.level,
    },
    {
      title: 'Soru Sayısı',
      key: 'questionCount',
      render: (_, record) => getQuestionCount(record.questionIds),
      sorter: (a, b) => getQuestionCount(a.questionIds) - getQuestionCount(b.questionIds),
    },
    {
      title: 'Süre (dk)',
      dataIndex: 'examDuration',
      key: 'examDuration',
      sorter: (a, b) => a.examDuration - b.examDuration,
    },
    {
      title: 'Sonuç Göster',
      dataIndex: 'showResult',
      key: 'showResult',
      render: (showResult) => (
        <Tag color={showResult ? 'green' : 'red'}>
          {showResult ? 'Evet' : 'Hayır'}
        </Tag>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        <Tag color={isActive ? 'success' : 'error'}>
          {isActive ? 'Aktif' : 'Pasif'}
        </Tag>
      ),
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) => moment(a.createDate) - moment(b.createDate),
    }
  ];

  return (
    <MainCard title="Sınav Listesi">
      <Table
        columns={columns}
        dataSource={exams}
        rowKey="id"
        loading={loading}
        pagination={{
          total: exams.length,
          pageSize: 10,
          showTotal: (total) => `Toplam ${total} sınav`,
          showSizeChanger: true,
        }}
      />
    </MainCard>
  );
};

export default ExamList; 