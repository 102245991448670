export default {
    translation: {
        LOGIN: "Login",
        YES: "YES",
        NO: "NO",
        NO_CONNECTION: "Operation could not be completed. Please check your internet connection.",
        NOT_RESPOND: "Service Not Responding",
        NOT_AUTHORAIZATION: "You do not have authorization to perform this action.",
        NOTIFICATION: "Notifications",
        WARNING: "Warnings",
        FORM_NAME: "Form Name",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        STATUS: "Status",
        EDIT: "Edit",
        ADD: "Add",
        DELETE: "Are you sure you want to delete?",
        DELETE_TITLE: "Delete",
        ADD_ROLE: "Add Role",
        ROLE_NAME: "Role Name",
        OPTIONS: "Options",
        BUSINESSLINE_CODE: "Business Line Code",
        BUSINESSLINE_NAME: "Business Line Name",
        ADD_BUSINESSLINE: "Add Business Line",
        ADD_JOBDESCRIPTION: "Add Job Description",
        ADD_POSITION: "Add Position",
        ADD_PERSONALNO: "Add Personnel Number",
        JOB_CODE: "Job Code",
        BUSINESS_LINE: "Business Line",
        JOB_NAME: "Job Name",
        JOB_DESCRIPTION: "Job Description",
        POSITION: "Position",
        PERSONAL_CODE: "Personnel Code",
        NAME: "Name",
        SURNAME: "Surname",
        CHOOSEFILE: "Choose File",
        UNIVERSITY: "University",
        FACULTY: "Faculty",
        DEPARTMENT: "Department",
        TYPE: "University Type",
        DETAILS: "Details",
        SENDMAIL: "Send Mail",
        CHOOSEOPTION: "Choose an Option",
        CHANGE_LANGUAGE: "Change Language",
        LOGIN_TITLE: "Akademitim Backoffice",
        USERNAME_MESSAGE:"Username cannot be blank", 
        PASSWORD_MESSAGE:"Password cannot be blank", 
        PASSWORD:"Password", 
        USERNAME:"Username",
        EDUCATION_DATAS: "Education Data",
        DOWNLOAD_EXCEL: "Download Excel Template",
        EXCEL_RULE: "*Excel should contain University, Faculty, and Department columns.",
        EXCEL_FILE_RULE: "File must be in xls or xlsx format.",
        UNIVERSITY_TYPE_RULE: "University Type selection is required.",
        FILE_RULE: "File attachment is required.",
        SHOW:"Show",
        SUCCESS:"Success",
        ERROR: "Error",
        SCREEN_NAME:"Screen name",
        AUTO:"Auto",
        ROLE_NAME:"Role Name",
        EMPLOYEE_NUMBER:"Employee Number",
        SCREEN_AUTO: "Screen auto",
        ADD_USER: "Add User",
        CHOOSE_ROLE:"Choose Role",
        ROLE_CONTROL: "Rol name is not empty",
        OPERATION:"Operations",
        ROLE_EDIT:"Role Edit",
        ROLE_EDIT_LABEL:"Role Name Edit",
        ROLE_AUTH:"Auth",
        ROLE_AUTH_LABEL:"Screen Auth",
        ROLE_CHOOSE:"Choose",
        ROLE_CHOOSE_LABEL:"Choose",
        CHOOSE_SCHOLAR: "Choose Scholar",
        CREATE_QR: "Create QR Code",
        DOWNLOAD_QR: "Download QR Code",
        PHONE: "Phone",
        EMAIL: "Email",
        TITLE: "Title",
        COMPANY: "Company",
        NAME_REQUIRED: "Name is required",
        SURNAME_REQUIRED: "Surname is required",
        PHONE_REQUIRED: "Phone is required",
        EMAIL_REQUIRED: "Email is required",
        EMAIL_INVALID: "Invalid email format",
        QR_DOWNLOAD_SUCCESS: "QR code downloaded successfully"
    }
}