export default {
    translation: {
        LOGIN: "Giriş Yap",
        YES: "EVET",
        NO: "HAYIR",
        NO_CONNECTION: "İşlem tamamlanamadı.İnternet bağlantınızı kontrol ediniz.",
        NOT_RESPOND: "Servis Yanıt Vermiyor",
        NOT_AUTHORAIZATION: "Yetkiniz olmadığından işlem yapılamıyor.",
        NOTIFICATION: "Bilgilendirmeler",
        WARNING: "Uyarılar",
        FORM_NAME: "Form Adı",
        START_DATE: "Açılış Tarihi",
        END_DATE: "Kapanış Tarihi",
        STATUS: "Durum",
        EDIT: "Düzenle",
        ADD: "Ekle",
        DELETE: "Silmek istediğinize emin misiniz?",
        DELETE_TITLE: "Sil",
        ADD_ROLE: "Rol Ekle",
        ROLE_NAME: "Rol Adı",
        OPTIONS: "İşlemler",
        BUSINESSLINE_CODE: "İş Kolu Kodu",
        BUSINESSLINE_NAME: "İş Kolu Adı",
        ADD_BUSINESSLINE: "İş Kolu Ekle",
        ADD_JOBDESCRIPTION: "İş Tanımı Ekle",
        ADD_POSITION: "Pozisyon Ekle",
        ADD_PERSONALNO: "Sicil Ekle",
        JOB_CODE: "İş Anahtarı Kodu",
        BUSINESS_LINE: "İş Kolu",
        JOB_NAME: "İş Anahtarı Tanımı",
        JOB_DESCRIPTION: "İş Tanımı",
        POSITION: "Pozisyon",
        PERSONAL_CODE: "Sicil Kodu",
        NAME: "Ad",
        SURNAME: "Soyad",
        CHOOSE_FILE: "Dosya Seç",
        UNIVERSITY: "Üniversite",
        FACULTY: "Fakülte",
        DEPARTMENT: "Bölüm",
        TYPE: "Üniversite Türü",
        DETAILS: "Detay",
        SEND_MAIL_STATU: "Mail Durumu",
        CHOOSE_OPTION: "İşlem Seçiniz",
        CHANGE_LANGUAGE: "Dil Değiştir",
        LOGIN_TITLE: "Akademitim Backoffice",
        USERNAME_MESSAGE: "Kullanıcı Adı Boş Bırakılamaz",
        PASSWORD_MESSAGE: "Şifre boş bırakılamaz",
        PASSWORD: "Şifre",
        USERNAME: "Kullanıcı Adı",
        EDUCATION_DATAS: "Eğitim Verileri",
        DOWNLOAD_EXCEL: "Excel Şablon İndir",
        EXCEL_RULE: "*Excel yapısında Üniversite, Fakülte ve Bölüm sütunları yer almalıdır.",
        EXCEL_FILE_RULE: "Dosya xls veya xlsx formatında olmalıdır.",
        UNIVERSITY_TYPE_RULE: "Üniversite Türü seçilmesi zorunludur.",
        FILE_RULE: "Dosya eklenmesi zorunludur.",
        APPLICANT_COUNT: "• 2024 Yılı Başvuran Sayısı: ",
        VIEW_SCHOLAR: "• 2024 Yılı Bursiyer Seçilen Öğrencileri Görüntülemek İçin ",
        POINT_DOCUMENT: "• Puan Yapısı Dokümanını İndirmek İçin ",
        CLICK: "Tıklayınız.",
        PERSON: "Kişi",
        SCHOLAR_REPORT: "Burs Başvuru Raporunu görmek için ",
        YEAR: "Yıl",
        SEARCH: "Arama",
        IDENTITY_NO: "TC",
        CHOOSE_YEAR: "Yıl seçiniz",
        SHOW: "Göster",
        WINNER_MAIL: "Kabul Maili Gönder",
        REJECTION_MAIL: "Red Maili Gönder",
        POPUP_REJECT: "Bursiyerler ekranına eklenen kişiler haricindeki başvuranlara red maili gönderilecektir. Emin misiniz?",
        POPUP_WINNER: "Bursiyerler ekranına eklenen kişilere kabul maili gönderilecektir. Emin misiniz?",
        SUCCESS: "Başarılı",
        ERROR: "Hata",
        POPUP_WINNER: "Bursiyerler ekranına eklenen kişilere kabul maili gönderilecektir. Emin misiniz?",
        SCREEN_NAME:"Ekran Adı",
        AUTO:"İşlem Yetkisi",
        ROLE_NAME:"Role Adı",
        EMPLOYEE_NUMBER:"Sicil Numarası",
        SCREEN_AUTO: "Ekran Yetkilendirme",
        ADD_USER: "Kullanıcı Ekle",
        CHOOSE_ROLE:"Rol Seç",
        ROLE_CONTROL: "Rol adı boş olamaz",
        OPERATION:"İşlem Yetkisi",
        ROLE_EDIT:"Düzenle",
        ROLE_EDIT_LABEL:"Role İsmi Düzenle",
        ROLE_AUTH:"Yetki",
        ROLE_AUTH_LABEL:"Ekran Yetkilendirme",
        ROLE_CHOOSE:"Seçiniz",
        ROLE_CHOOSE_LABEL:"İşlem Seçiniz",
        CHOOSE_SCHOLAR: "Bursiyer Seçiniz",
        CREATE_QR: "QR Kod Oluştur",
        DOWNLOAD_QR: "QR Kodu İndir",
        PHONE: "Telefon",
        EMAIL: "E-posta",
        TITLE: "Ünvan",
        COMPANY: "Şirket",
        NAME_REQUIRED: "İsim zorunludur",
        SURNAME_REQUIRED: "Soyisim zorunludur",
        PHONE_REQUIRED: "Telefon zorunludur",
        EMAIL_REQUIRED: "E-posta zorunludur",
        EMAIL_INVALID: "Geçersiz e-posta formatı",
        QR_DOWNLOAD_SUCCESS: "QR kod başarıyla indirildi"
    }
}