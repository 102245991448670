import { Config } from "../utils/config";
export const Endpoints = {
  IDENTITY: {
    TOKEN: Config.API_URL + "/Auth/Get-Token",
    GET_USER_INFO: Config.API_URL + "/Auth/User-Info",
  
  },
  CERTIFICATE: {
    FILE: Config.API_URL + "/Certificate/File",
    POST:Config.API_URL + "/Certificate/List",
    GET:Config.API_URL + "/Certificate",
    MAIL:Config.API_URL + "/Certificate/Mail",
    GET_LOGO:Config.API_URL + "/Certificate/Logo",
    POST_LOGO:Config.API_URL + "/Certificate/Logo",

  
  },
  DISCOUNT: {
    CREATE: Config.API_URL + "/Discount",
    DELETE: Config.API_URL + "/Discount",
    GET: Config.API_URL + "/Discount"
  },
  USER: {
    GET: Config.API_URL + "/User",
    UPDATE_TOKEN: Config.API_URL + "/User/update-token",
    ASSIGN_EXAM: Config.API_URL + "/User/assign-exam"
  },
  EXAM: {
    GET_ALL: Config.API_URL + "/Exam/exams",
    GET_PERMISSIONS: Config.API_URL + "/Exam/permissions"
  },
  TOKEN: {
    GET_HISTORIES: Config.API_URL + "/Token/buy-histories"
  },
  MARKET: {
    GET_EXAM_HISTORIES: Config.API_URL + "/Market/exam-buy-histories"
  }
};
