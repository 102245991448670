import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../helpers/api/apiCall';
import { Endpoints } from '../../../utils/Endpoints';

const initialState = {
  users: [],
  loading: false,
  error: null
};

// Get all users
export const getUsers = createAsyncThunk(
  'user/getAll',
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.USER.GET, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

// Update user token
export const updateUserToken = createAsyncThunk(
  'user/updateToken',
  async (updateData, thunkAPI) => {
    const response = await client.put(Endpoints.USER.UPDATE_TOKEN, updateData, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

// Assign exam to user
export const assignExam = createAsyncThunk(
  'user/assignExam',
  async (assignData, thunkAPI) => {
    const response = await client.post(Endpoints.USER.ASSIGN_EXAM, assignData, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get users
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Update token
      .addCase(updateUserToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserToken.fulfilled, (state, action) => {
        state.loading = false;
        // Burada gerekirse state güncellemesi yapılabilir
      })
      .addCase(updateUserToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Assign exam
      .addCase(assignExam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignExam.fulfilled, (state, action) => {
        state.loading = false;
        // Burada gerekirse state güncellemesi yapılabilir
      })
      .addCase(assignExam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default userSlice.reducer; 