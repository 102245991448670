import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../helpers/api/apiCall';
import { Endpoints } from '../../../utils/Endpoints';

const initialState = {
  purchases: [],
  examPurchases: [],
  loading: false,
  error: null
};

// Get token purchase histories
export const getTokenPurchases = createAsyncThunk(
  'token/getPurchases',
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.TOKEN.GET_HISTORIES, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

// Get exam purchase histories
export const getExamPurchases = createAsyncThunk(
  'token/getExamPurchases',
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.MARKET.GET_EXAM_HISTORIES, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTokenPurchases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTokenPurchases.fulfilled, (state, action) => {
        state.loading = false;
        state.purchases = action.payload;
      })
      .addCase(getTokenPurchases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getExamPurchases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExamPurchases.fulfilled, (state, action) => {
        state.loading = false;
        state.examPurchases = action.payload;
      })
      .addCase(getExamPurchases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default tokenSlice.reducer; 