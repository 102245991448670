import { createContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { client } from '../helpers/api/apiCall';
import { setToken, setUser, setLoading } from '../store/slices/auth';
import { Endpoints } from '../utils/Endpoints';


const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const authState = useSelector((state) => state.auth);
    let navigate = useNavigate();
    const verifyToken = (serviceToken) => {
        if (!serviceToken) {
            return false;
        }
        const decoded = jwtDecode(serviceToken);

        return decoded.exp > Date.now() / 1000;
    };

    const dispatch = useDispatch();
    const init = async (token,userIdentifier) => {
        try {
            if (token && verifyToken(token)) {
                const response = await client.get(Endpoints.IDENTITY.GET_USER_INFO+"?Email="+userIdentifier, {
                    token: token,
                });
                const { success, payload } = response.data;

                if (success && payload.role === "Admin") {
                    dispatch(setUser(payload))
                    navigate("/Home")
                    
                } else {
                    dispatch(setUser({}))
                    alert("Hatalı giriş bilgileri")
                }

            } else {

            }
        } catch (err) {
            console.error(err);

        }
    };
    useEffect(() => {
        

        
    }, [authState.token]);

    const login = async (userIdentifier, password) => {
        try {
            dispatch(setLoading(true));
            const response = await client.post(Endpoints.IDENTITY.TOKEN, { email: userIdentifier, password: password });
            const { success, payload } = response.data;
            if (success && payload) {
                dispatch(setToken(payload))
                await init(payload,userIdentifier);
            } else {
                dispatch(setToken(''))
                dispatch(setUser({}))
                dispatch(setLoading(false));
            }
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setToken(''))
            dispatch(setUser({}))
        }
    };

    const logout = () => {
        dispatch(setToken(''))
        dispatch(setUser({}))
    };

    return (
        <JWTContext.Provider value={{ login, logout }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;