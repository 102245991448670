import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../helpers/api/apiCall';
import { Endpoints } from '../../../utils/Endpoints';

const initialState = {
  exams: [],
  permissions: [],
  loading: false,
  error: null
};

// Get all exams
export const getExams = createAsyncThunk(
  'exam/getAll',
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.EXAM.GET_ALL, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

// Get exam permissions
export const getExamPermissions = createAsyncThunk(
  'exam/getPermissions',
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.EXAM.GET_PERMISSIONS, {
      token: thunkAPI.getState().auth.token
    });
    return response.data.payload;
  }
);

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get exams
      .addCase(getExams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.loading = false;
        state.exams = action.payload;
      })
      .addCase(getExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Get permissions
      .addCase(getExamPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExamPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload;
      })
      .addCase(getExamPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default examSlice.reducer; 