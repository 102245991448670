import React, { useEffect } from 'react';
import { Table, Space, Tag, Row, Col, Card, Statistic } from 'antd';
import MainCard from '../../components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenPurchases } from '../../store/slices/token';
import moment from 'moment';
import { WalletOutlined, UserOutlined, ShoppingCartOutlined, DollarOutlined } from '@ant-design/icons';

const TokenPurchases = () => {
  const dispatch = useDispatch();
  const { purchases, loading } = useSelector((state) => state.token);

  useEffect(() => {
    dispatch(getTokenPurchases());
  }, [dispatch]);

  // İstatistik hesaplamaları
  const statistics = {
    totalUsers: purchases.length,
    totalPurchases: purchases.reduce((sum, user) => sum + user.tokenPurchases.length, 0),
    totalTokens: purchases.reduce((sum, user) => 
      sum + user.tokenPurchases.reduce((tokenSum, purchase) => tokenSum + purchase.tokenCount, 0), 0
    ),
    totalRevenue: purchases.reduce((sum, user) => 
      sum + user.tokenPurchases.reduce((revenueSum, purchase) => revenueSum + Number(purchase.tokenPrice), 0), 0
    ),
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Token Paketi',
        dataIndex: 'tokenName',
        key: 'tokenName',
      },
      {
        title: 'Token Miktarı',
        dataIndex: 'tokenCount',
        key: 'tokenCount',
        render: (count) => count.toLocaleString(),
      },
      {
        title: 'Paket Fiyatı',
        dataIndex: 'tokenPrice',
        key: 'tokenPrice',
        render: (price) => `₺${price}`,
      },
      {
        title: 'Birim Fiyat',
        key: 'unitPrice',
        render: (_, record) => `₺${(record.tokenPrice / record.tokenCount).toFixed(2)}`,
      },
      {
        title: 'Satın Alma Tarihi',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.tokenPurchases}
        pagination={false}
        rowKey="id"
      />
    );
  };

  const columns = [
    {
      title: 'Kullanıcı Adı',
      key: 'userName',
      render: (_, record) => `${record.userName} ${record.userSurname}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'userEmail',
      key: 'userEmail',
    },
    {
      title: 'Toplam Alım Sayısı',
      key: 'totalPurchases',
      render: (_, record) => record.tokenPurchases.length,
    },
    {
      title: 'Toplam Token',
      key: 'totalTokens',
      render: (_, record) => 
        record.tokenPurchases
          .reduce((sum, purchase) => sum + purchase.tokenCount, 0)
          .toLocaleString(),
    },
    {
      title: 'Toplam Harcama',
      key: 'totalSpent',
      render: (_, record) => 
        `₺${record.tokenPurchases
          .reduce((sum, purchase) => sum + Number(purchase.tokenPrice), 0)
          .toFixed(2)}`,
    }
  ];

  return (
    <MainCard title="Token Alım Geçmişi">
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Kullanıcı"
                value={statistics.totalUsers}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Alım"
                value={statistics.totalPurchases}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Token"
                value={statistics.totalTokens.toLocaleString()}
                prefix={<WalletOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Kazanç"
                value={statistics.totalRevenue.toFixed(2)}
                prefix={<DollarOutlined />}
                suffix="₺"
              />
            </Card>
          </Col>
        </Row>

        <Table
          columns={columns}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: true,
          }}
          dataSource={purchases}
          rowKey="userId"
          loading={loading}
          pagination={{
            total: purchases.length,
            pageSize: 10,
            showTotal: (total) => `Toplam ${total} kullanıcı`,
            showSizeChanger: true,
          }}
        />
      </Space>
    </MainCard>
  );
};

export default TokenPurchases; 