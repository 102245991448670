import React, { useState } from "react";
import { Divider, Layout, Menu, Image, Button } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAntDesignIcon } from "../../helpers/index";
import { setUserNull } from "../../../src/store/slices/identity";
import {
  HomeFilled,
  LogoutOutlined,
  UserOutlined,
  FundViewOutlined,
  FileOutlined,
  StarOutlined,
  FundOutlined,
  KeyOutlined,
  OrderedListOutlined,
  SettingFilled,
  CopyOutlined,
  PlusCircleOutlined,
  FundProjectionScreenOutlined,
  SafetyCertificateOutlined,
  UploadOutlined,
  PictureOutlined,
  CloudUploadOutlined,
  TagOutlined,
  QrcodeOutlined,
  BookOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
export default function PersistentDrawerLeft(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [current, setCurrent] = useState("Home");
  const [pageName, setPageName] = useState("Home");

  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  const authScreens = useSelector((state) => state.identity.authorizedScreens);



  const user = useSelector(state => state.auth.user)
  let location = useLocation();


  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem("Ana Sayfa", "Home", <HomeFilled />),
    getItem("Sertifika İşlemleri", "sub1", <SafetyCertificateOutlined />, [
      getItem("Sertifika Dosya Yükle", "Import", <CloudUploadOutlined />),
      getItem("Sertifikalar", "Certificates", <CopyOutlined />),
      getItem("Logolar", "Logos", <PictureOutlined />)
    ]),
    getItem("Kullanıcı İşlemleri", "sub2", <UserOutlined />, [
      getItem("Kullanıcı Listesi", "Users", <OrderedListOutlined />),
      getItem("Sınav Tanımlama", "ExamAssignment", <FileOutlined />)
    ]),
    getItem("Sınav İşlemleri", "sub4", <BookOutlined />, [
      getItem("Sınav Listesi", "ExamList", <UnorderedListOutlined />),
      getItem("Sınav Oluştur", "CreateExam", <PlusCircleOutlined />),
    ]),
    getItem("Token İşlemleri", "sub3", <FundOutlined />, [
      getItem("Token Alımları", "TokenPurchases", <FundViewOutlined />),
      getItem("Token Harcamaları", "TokenUsage", <FundProjectionScreenOutlined />),

    ]),
    getItem("İndirim Kuponları", "Coupons", <TagOutlined />),
    getItem("VCard QR Oluştur", "VCardQr", <QrcodeOutlined />)
  ];


  if (authScreens !== undefined && authScreens.length > 0) {
    authScreens.map((screen) => {
      const childItems = [];
      screen.childScreens?.map((child) => {
        childItems.push(getItem(child.name, child.tag));
      });

      if (childItems.length > 0)
        items.push(
          getItem(
            screen.name,
            screen.tag,
            getAntDesignIcon(screen.icon),
            childItems
          )
        );
      else
        items.push(
          getItem(screen.name, screen.tag, getAntDesignIcon(screen.icon))
        );
    });
  }


  const onClick = (e) => {
    if (e.keyPath.length === 1)
      setPageName(items.filter(x => x.key === e.key)[0].label)
    if (e.keyPath.length > 1) {
      var menu = items.filter(x => x.key === e.keyPath[e.keyPath.length - 1])
      if (menu.length > 0) {
        setPageName(menu[0].children.filter(x => x.key === e.keyPath[0])[0].label)
      }
    }

    if (e.key === "Login") {
      dispatch(setUserNull());
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      navigate(e.key);
      return;
    }

    setCurrent(e.key);
    navigate(e.key);
  };



  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/");
  };

  const getCurrentLabel = () => {
    var isParent = items.filter(x => x.key === location.pathname.slice(1))

    if (isParent.length > 0 && isParent[0].children == undefined)
      return items.filter(x => x.key === location.pathname.slice(1))[0].label

    var childrens = items.filter(x => x.children != undefined)
    var label = ''
    childrens.map(parent => {
      var isExist = parent.children.filter(x => x.key == location.pathname.slice(1));
      if (isExist.length > 0)
        label = isExist[0].label
    })
    return label
  }


  return (
    <div>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div
            style={{
              alignContent: "center",
              justifyContent: "center",
              display: "flex",
              height: "32px",
              margin: "16px",
              marginBottom: "32px" // Butondan sonra biraz boşluk bırakmak için
            }}
          >
            <Image
              src={require("../../assets/img/tim_logo_vector.png")}
              style={{ padding: 20, width: "180px", height: "80px" }}
              preview={false}
            />
            {/* Kullanıcı Adı */}
          </div>
          {/* <span style={{ marginLeft: "8px", color: 'white' }}><UserOutlined />{' ' + user.name + ' ' + user.surname}</span> */}

          <Menu
            theme="dark"
            onClick={onClick}
            selectedKeys={[location.pathname.slice(1)]}
            mode="vertical"
            items={items}
            style={{ marginTop: 5 }}
          />
        </Layout.Sider>

        <Layout className="site-layout">
          <Layout.Content
            style={{
              margin: "0 16px",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              <Divider>{getCurrentLabel()}</Divider>
              <Outlet />
            </div>

          </Layout.Content>
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ position: "fixed", bottom: 16, right: 16 }}
          />
        </Layout>
      </Layout>
    </div>
  );
}
