import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../../helpers/client";
import { Endpoints } from "../../../utils/Endpoints";
import { act } from "react-dom/test-utils";

const initialState = {

  loading: false,

};

export const addFile = createAsyncThunk("addFile", async (body, thunkAPI) => {
  const response = await client.fileUpload(Endpoints.CERTIFICATE.FILE, body, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const addCertificas = createAsyncThunk("addCertificas", async (body, thunkAPI) => {
  const response = await client.post(Endpoints.CERTIFICATE.POST, body, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const getCertificates = createAsyncThunk("getCertificates", async (body, thunkAPI
) => {
  const response = await client.get(Endpoints.CERTIFICATE.GET, body, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const getLogos = createAsyncThunk("getLogos", async (_, thunkAPI) => {
  const response = await client.get(Endpoints.CERTIFICATE.GET_LOGO, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const getCertificate = createAsyncThunk("getCertificate", async (id, thunkAPI) => {
  const response = await client.get(Endpoints.CERTIFICATE.GET + "/id?id=" + id, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const sendMail = createAsyncThunk("sendMail", async (body, thunkAPI) => {
  const response = await client.post(Endpoints.CERTIFICATE.MAIL, body, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const addLogo = createAsyncThunk("addLogo", async (body, thunkAPI) => {
  const response = await client.fileUpload(Endpoints.CERTIFICATE.POST_LOGO, body, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});


const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder.addCase(addFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addFile.rejected, (state, action) => {
      state.loading = false;
    });





  },
});

export const { } =
  certificateSlice.actions;
export default certificateSlice.reducer;
