import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Tag, Input, Button, Modal, InputNumber, message } from 'antd';
import { getUsers, updateUserToken } from '../../store/slices/user';
import MainCard from '../../components/MainCard';
import { EditOutlined } from '@ant-design/icons';

const { Search } = Input;

const UsersPage = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tokenAmount, setTokenAmount] = useState(0);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleTokenEdit = (record) => {
    setSelectedUser(record);
    setTokenAmount(record.tokenCount);
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      await dispatch(updateUserToken({
        userId: selectedUser.id,
        tokenAmount: tokenAmount
      })).unwrap();
      
      message.success('Token miktarı başarıyla güncellendi');
      setIsModalVisible(false);
      dispatch(getUsers()); // Listeyi yenile
    } catch (error) {
      message.error('Token güncellenirken bir hata oluştu');
    }
  };

  const columns = [
    {
      title: 'Ad',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Soyad',
      dataIndex: 'surname',
      key: 'surname',
      sorter: (a, b) => a.surname.localeCompare(b.surname),
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => text || '-',
    },
    {
      title: 'Token Miktarı',
      dataIndex: 'tokenCount',
      key: 'tokenCount',
      sorter: (a, b) => a.tokenCount - b.tokenCount,
    },
    {
      title: 'Durum',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        <Tag color={isActive ? 'success' : 'error'}>
          {isActive ? 'Aktif' : 'Pasif'}
        </Tag>
      ),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="primary" 
          icon={<EditOutlined />}
          onClick={() => handleTokenEdit(record)}
        >
          Token Düzenle
        </Button>
      ),
    }
  ];

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredData = users.filter((record) => {
    const searchValue = searchText.toLowerCase();
    return (
      record.name?.toLowerCase().includes(searchValue) ||
      record.surname?.toLowerCase().includes(searchValue) ||
      record.email?.toLowerCase().includes(searchValue)
    );
  });

  return (
    <MainCard title="Kullanıcı Listesi">
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Search
          placeholder="Kullanıcı ara..."
          allowClear
          enterButton="Ara"
          size="large"
          onSearch={handleSearch}
          style={{ maxWidth: 500 }}
        />

        <Table
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            total: filteredData.length,
            pageSize: 10,
            showTotal: (total) => `Toplam ${total} kullanıcı`,
            showSizeChanger: true,
          }}
        />

        <Modal
          title="Token Miktarını Düzenle"
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => setIsModalVisible(false)}
          okText="Kaydet"
          cancelText="İptal"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <p>
              <strong>Kullanıcı:</strong> {selectedUser?.name} {selectedUser?.surname}
            </p>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              value={tokenAmount}
              onChange={(value) => setTokenAmount(value)}
              placeholder="Token miktarı giriniz"
            />
          </Space>
        </Modal>
      </Space>
    </MainCard>
  );
};

export default UsersPage; 