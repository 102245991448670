import React, { useState, useRef } from 'react';
import { Layout, Form, Input, Button, QRCode, Space, message, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

const VCardQrPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [vCardQr, setVCardQr] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const qrRef = useRef(null);

  const createVCard = (values) => {
    console.log('Form values:', values);

    const vCard = `BEGIN:VCARD
VERSION:3.0
N:${values.surname};${values.name};;;
FN:${values.name} ${values.surname}
TEL;TYPE=CELL:${values.phone}
EMAIL;TYPE=INTERNET:${values.email}
TITLE:${values.title || ''}
ORG:${values.company || ''}
END:VCARD`;

    console.log('Generated vCard:', vCard);
    setVCardQr(vCard);
  };

  const downloadQR = () => {
    if (qrRef.current) {
      const canvas = document.createElement('canvas');
      const size = 200; // QR kod boyutu
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');
      
      // Beyaz arka plan
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, size, size);
      
      // QR kodu çiz
      const qrImage = qrRef.current.querySelector('canvas');
      if (qrImage) {
        ctx.drawImage(qrImage, 0, 0, size, size);
        
        // Form değerlerini al
        const formValues = form.getFieldsValue();
        const fileName = `${formValues.name}_${formValues.surname}_qr.png`;
        
        // PNG olarak indir
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        downloadLink.href = pngFile;
        downloadLink.click();
        
        messageApi.success(t('QR_DOWNLOAD_SUCCESS'));
      }
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', padding: '20px' }}>
      {contextHolder}
      <Row gutter={24}>
        <Col span={12}>
          <Form
            form={form}
            onFinish={createVCard}
            layout="vertical"
            style={{ maxWidth: 600 }}
          >
            <Form.Item
              name="name"
              label={t('NAME')}
              rules={[{ required: true, message: t('NAME_REQUIRED') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="surname"
              label={t('SURNAME')}
              rules={[{ required: true, message: t('SURNAME_REQUIRED') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone"
              label={t('PHONE')}
              rules={[{ required: true, message: t('PHONE_REQUIRED') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('EMAIL')}
              rules={[
                { required: true, message: t('EMAIL_REQUIRED') },
                { type: 'email', message: t('EMAIL_INVALID') }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="title"
              label={t('TITLE')}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="company"
              label={t('COMPANY')}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('CREATE_QR')}
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          {vCardQr && (
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              position: 'sticky',
              top: 20
            }}>
              <div ref={qrRef}>
                <QRCode 
                  value={vCardQr} 
                  size={200}
                  style={{ marginBottom: 16 }}
                  bgColor="#FFFFFF"
                />
              </div>
              <Button 
                type="primary" 
                icon={<DownloadOutlined />}
                onClick={downloadQR}
              >
                {t('DOWNLOAD_QR')}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default VCardQrPage; 