import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const MainCard = ({ children, title, ...others }) => {
  return (
    <Card
      title={title}
      bordered={false}
      style={{
        borderRadius: '10px',
        marginBottom: '16px'
      }}
      {...others}
    >
      {children}
    </Card>
  );
};

MainCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default MainCard; 