import React, { useState } from 'react';
import { 
  Upload, 
  Button, 
  message, 
  Table, 
  Modal, 
  Space,
  Form,
  Input,
  Card
} from 'antd';
import { UploadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import MainCard from '../../components/MainCard';

const CreateExam = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [exams, setExams] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [examName, setExamName] = useState('');
  const [fileList, setFileList] = useState([]);

  const questionColumns = [
    {
      title: 'Soru',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Doğru Cevap',
      dataIndex: 'correctAnswer',
      key: 'correctAnswer',
    },
    {
      title: 'Seçenekler',
      dataIndex: 'options',
      key: 'options',
      render: (options) => options.join(', '),
    },
  ];

  const examColumns = [
    {
      title: 'Sınav Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Soru Sayısı',
      dataIndex: 'questionCount',
      key: 'questionCount',
      sorter: (a, b) => a.questionCount - b.questionCount,
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            icon={<EyeOutlined />} 
            onClick={() => handlePreviewExam(record)}
          >
            Görüntüle
          </Button>
          <Button 
            type="primary" 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteExam(record)}
          >
            Sil
          </Button>
        </Space>
      ),
    },
  ];

  const uploadProps = {
    beforeUpload: (file) => {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('Sadece Excel dosyası yükleyebilirsiniz!');
        return false;
      }
      return true;
    },
    onChange: (info) => {
      setFileList(info.fileList.slice(-1));
      if (info.file.status === 'done') {
        // API çağrısı simülasyonu
        setLoading(true);
        setTimeout(() => {
          const mockQuestions = [
            {
              key: '1',
              question: 'JavaScript nedir?',
              correctAnswer: 'Programlama dili',
              options: ['Programlama dili', 'Veritabanı', 'İşletim sistemi', 'Browser'],
            },
            // ... diğer sorular
          ];
          setQuestions(mockQuestions);
          setIsModalVisible(true);
          setLoading(false);
        }, 1000);
      }
    },
  };

  const handleCreateExam = () => {
    if (!examName.trim()) {
      message.error('Lütfen sınav adı girin');
      return;
    }

    const newExam = {
      key: Date.now(),
      name: examName,
      questionCount: questions.length,
      createdAt: new Date().toLocaleDateString('tr-TR'),
      questions: questions,
    };

    setExams(prev => [...prev, newExam]);
    setIsModalVisible(false);
    setQuestions([]);
    setExamName('');
    setFileList([]);
    message.success('Sınav başarıyla oluşturuldu');
  };

  const handlePreviewExam = (exam) => {
    Modal.info({
      title: exam.name,
      width: 800,
      content: (
        <Table
          columns={questionColumns}
          dataSource={exam.questions}
          pagination={false}
        />
      ),
    });
  };

  const handleDeleteExam = (exam) => {
    setExams(prev => prev.filter(e => e.key !== exam.key));
    message.success('Sınav silindi');
  };

  return (
    <MainCard title="Sınav Oluştur">
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card>
          <Upload {...uploadProps} fileList={fileList}>
            <Button icon={<UploadOutlined />} loading={loading}>
              Soru Dosyası Yükle
            </Button>
          </Upload>
        </Card>

        <Table
          columns={examColumns}
          dataSource={exams}
          pagination={{
            total: exams.length,
            pageSize: 10,
            showTotal: (total) => `Toplam ${total} kayıt`,
          }}
        />

        <Modal
          title="Soruları Onayla"
          visible={isModalVisible}
          onOk={handleCreateExam}
          onCancel={() => setIsModalVisible(false)}
          width={800}
          okText="Sınavı Oluştur"
          cancelText="İptal"
        >
          <Form layout="vertical">
            <Form.Item 
              label="Sınav Adı"
              required
              rules={[{ required: true, message: 'Lütfen sınav adı girin' }]}
            >
              <Input 
                value={examName}
                onChange={(e) => setExamName(e.target.value)}
                placeholder="Sınav adını girin"
              />
            </Form.Item>
          </Form>
          <Table
            columns={questionColumns}
            dataSource={questions}
            pagination={false}
          />
        </Modal>
      </Space>
    </MainCard>
  );
};

export default CreateExam; 