import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDiscount, deleteDiscount, getDiscounts } from '../../store/slices/discount';
import {
  Card,
  Form,
  Input,
  Button,
  Radio,
  InputNumber,
  Table,
  Space,
  Tag,
  Typography,
  message,
  Popconfirm
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  TagOutlined,
  PercentageOutlined,
  DollarOutlined
} from '@ant-design/icons';
import MainCard from '../../components/MainCard';
import moment from 'moment';

const { Text } = Typography;

const Coupons = () => {
  const dispatch = useDispatch();
  const { loading, discounts, error } = useSelector((state) => state.discount);
  const [form] = Form.useForm();
  const [discountType, setDiscountType] = useState('percentage');

  useEffect(() => {
    loadCoupons();
  }, [dispatch]);

  const loadCoupons = () => {
    dispatch(getDiscounts());
  };

  const columns = [
    {
      title: 'Kupon Kodu',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <Tag icon={<TagOutlined />} color="blue">
          {text}
        </Tag>
      ),
    },
    {
      title: 'İndirim Tipi',
      dataIndex: 'isPercantage',
      key: 'isPercantage',
      render: (isPercantage) => (
        <Tag icon={isPercantage ? <PercentageOutlined /> : <DollarOutlined />}>
          {isPercantage ? 'Yüzde İndirim' : 'Tutar İndirim'}
        </Tag>
      ),
      filters: [
        { text: 'Yüzde İndirim', value: true },
        { text: 'Tutar İndirim', value: false },
      ],
      onFilter: (value, record) => record.isPercantage === value,
    },
    {
      title: 'İndirim Değeri',
      dataIndex: 'amount',
      key: 'amount',
      render: (value, record) => (
        <Text strong>
          {record.isPercantage ? `%${value}` : `₺${value}`}
        </Text>
      ),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text) => moment(text).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) => moment(a.createDate) - moment(b.createDate),
    },
    {
      title: 'Durum',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        <Tag color={isActive ? 'success' : 'error'}>
          {isActive ? 'Aktif' : 'Pasif'}
        </Tag>
      ),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_, record) => (
        <Popconfirm
          title="Kuponu Sil"
          description="Bu kuponu silmek istediğinizden emin misiniz?"
          onConfirm={() => handleDelete(record)}
          okText="Evet"
          cancelText="Hayır"
        >
          <Button type="primary" danger icon={<DeleteOutlined />}>
            Sil
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleSubmit = async (values) => {
    try {
      await dispatch(createDiscount({
        code: values.code,
        discountType: values.discountType === 'percentage',
        discountValue: values.discountValue
      })).unwrap();
      
      message.success('Kupon başarıyla oluşturuldu');
      form.resetFields();
      loadCoupons();
    } catch (error) {
      message.error('Kupon oluşturulurken bir hata oluştu');
    }
  };

  const handleDelete = async (record) => {
    try {
      await dispatch(deleteDiscount(record.id)).unwrap();
      message.success('Kupon başarıyla silindi');
      loadCoupons(); // Listeyi yenile
    } catch (error) {
      message.error('Kupon silinirken bir hata oluştu');
    }
  };

  return (
    <MainCard title="İndirim Kuponları">
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card title="Yeni Kupon Oluştur">
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Space wrap style={{ width: '100%' }} size="large">
              <Form.Item
                name="code"
                label="Kupon Kodu"
                rules={[{ required: true, message: 'Lütfen kupon kodu girin' }]}
              >
                <Input
                  prefix={<TagOutlined />}
                  style={{ width: 200 }}
                  placeholder="Örn: BAHAR2024"
                />
              </Form.Item>

              <Form.Item
                name="discountType"
                label="İndirim Tipi"
                initialValue="percentage"
              >
                <Radio.Group 
                  onChange={(e) => setDiscountType(e.target.value)}
                  value={discountType}
                >
                  <Radio.Button value="percentage">
                    <PercentageOutlined /> Yüzde İndirim
                  </Radio.Button>
                  <Radio.Button value="fixed">
                    <DollarOutlined /> Tutar İndirim
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="discountValue"
                label="İndirim Değeri"
                rules={[{ required: true, message: 'Lütfen indirim değeri girin' }]}
              >
                <InputNumber
                  min={1}
                  max={discountType === 'percentage' ? 100 : 1000}
                  prefix={discountType === 'percentage' ? '%' : '₺'}
                  style={{ width: 150 }}
                />
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  icon={<PlusOutlined />}
                  style={{ marginTop: 29 }}
                >
                  Kupon Oluştur
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>

        <Table
          columns={columns}
          dataSource={discounts || []}
          loading={loading}
          pagination={{
            total: discounts?.length || 0,
            pageSize: 10,
            showTotal: (total) => `Toplam ${total} kupon`,
            showSizeChanger: true,
          }}
        />
      </Space>
    </MainCard>
  );
};

export default Coupons; 