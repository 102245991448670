import React, { useEffect, useState } from 'react';
import { Card, Form, Select, InputNumber, Button, Table, Space, message } from 'antd';
import MainCard from '../../components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getExams, getExamPermissions } from '../../store/slices/exam';
import { getUsers, assignExam } from '../../store/slices/user';

const ExamAssignment = () => {
  const dispatch = useDispatch();
  const { exams, permissions, loading: examLoading } = useSelector((state) => state.exam);
  const { users, loading: userLoading } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getExams());
    dispatch(getUsers());
    dispatch(getExamPermissions());
  }, [dispatch]);

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Sınav Adı',
        dataIndex: 'examName',
        key: 'examName',
      },
      {
        title: 'Hak Sayısı',
        dataIndex: 'numberOfClaims',
        key: 'numberOfClaims',
        render: (claims) => claims || 0,
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.examPermissions}
        pagination={false}
        rowKey="id"
      />
    );
  };

  const columns = [
    {
      title: 'Kullanıcı Adı',
      dataIndex: 'userName',
      key: 'userName',
      render: (_, record) => `${record.userName} ${record.userSurname}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'userEmail',
      key: 'userEmail',
    },
    {
      title: 'Toplam Sınav Sayısı',
      key: 'totalExams',
      render: (_, record) => record.examPermissions.length,
    }
  ];

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(assignExam({
        userId: values.studentId,
        examId: values.examId,
        numberOfClaims: values.numberOfClaims
      })).unwrap();
      
      message.success('Sınav başarıyla tanımlandı');
      form.resetFields();
    } catch (error) {
      message.error('Sınav tanımlanırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainCard title="Sınav Tanımlama">
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="studentId"
              label="Öğrenci"
              rules={[{ required: true, message: 'Lütfen öğrenci seçin' }]}
            >
              <Select
                placeholder="Öğrenci seçin"
                loading={userLoading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {users.map(user => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name} {user.surname} - {user.email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="examId"
              label="Sınav"
              rules={[{ required: true, message: 'Lütfen sınav seçin' }]}
            >
              <Select
                placeholder="Sınav seçin"
                loading={examLoading}
                showSearch
                optionFilterProp="children"
              >
                {exams.map(exam => (
                  <Select.Option key={exam.id} value={exam.id}>
                    {exam.name} - {exam.examDescription} ({exam.level}. Seviye)
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="numberOfClaims"
              label="Hak Sayısı"
              rules={[{ required: true, message: 'Lütfen hak sayısı girin' }]}
            >
              <InputNumber 
                min={1} 
                style={{ width: '100%' }} 
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Sınavı Tanımla
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Table
          title={() => <h3>Sınav İzinleri</h3>}
          columns={columns}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: true,
          }}
          dataSource={permissions}
          rowKey="userId"
          loading={examLoading}
        />
      </Space>
    </MainCard>
  );
};

export default ExamAssignment; 