// project imports
import MainLayout from '../components/layout';
import AuthGuard from '../utils/AuthGuard';

// sample page routing

import HomePage from '../views/home';
import ImportFilePage from '../views/certificate/importFile';
import CertificatesPage from '../views/certificate/certificates';
import LogosPage from '../views/logo';
import Users from '../views/users';

import ExamAssignment from '../views/examAssignment';
import CreateExam from '../views/createExam';
import TokenPurchases from '../views/tokenPurchases';
import TokenUsage from '../views/tokenUsage';
import Coupons from '../views/coupons';
import VCardQrPage from '../views/vcardQr';
import ExamList from '../views/examList';


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/Home',
      element: <HomePage/>
    }, 
    {
      path: '/Import',
      element: <ImportFilePage/>
    }, 
    {
      path: '/Certificates',
      element: <CertificatesPage/>
    }, 
    {
      path: '/Logos',
      element: <LogosPage/>
    },
    {
      path: '/Users',
      element: <Users />
    },
    {
      path: '/ExamAssignment',
      element: <ExamAssignment />
    },
    {
      path: '/CreateExam',
      element: <CreateExam />
    },
    {
      path: '/TokenPurchases',
      element: <TokenPurchases />
    },
    {
      path: '/TokenUsage',
      element: <TokenUsage />
    },
    {
      path: '/Coupons',
      element: <Coupons />
    },
    {
      path: '/VCardQr',
      element: <VCardQrPage />
    },
    {
      path: '/ExamList',
      element: <ExamList />
    }
  ]
};

export default MainRoutes;