import React, { useEffect } from 'react';
import { Table, Space, Card, Row, Col, Statistic } from 'antd';
import MainCard from '../../components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getExamPurchases } from '../../store/slices/token';
import moment from 'moment';
import { BookOutlined, UserOutlined, ShoppingCartOutlined, FieldTimeOutlined } from '@ant-design/icons';

const TokenUsage = () => {
  const dispatch = useDispatch();
  const { examPurchases, loading } = useSelector((state) => state.token);

  useEffect(() => {
    dispatch(getExamPurchases());
  }, [dispatch]);

  // İstatistik hesaplamaları
  const statistics = {
    totalUsers: examPurchases.length,
    totalPurchases: examPurchases.reduce((sum, user) => sum + user.examPurchases.length, 0),
    totalExams: examPurchases.reduce((sum, user) => 
      sum + user.examPurchases.reduce((examSum, purchase) => examSum + purchase.exams.length, 0), 0
    ),
    totalDuration: examPurchases.reduce((sum, user) => 
      sum + user.examPurchases.reduce((durationSum, purchase) => 
        durationSum + purchase.exams.reduce((examDuration, exam) => examDuration + exam.examDuration, 0), 0
      ), 0
    ),
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Satın Alma Tarihi',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Sınavlar',
        dataIndex: 'exams',
        key: 'exams',
        render: (exams) => (
          <ul style={{ margin: 0, paddingLeft: 20 }}>
            {exams.map(exam => (
              <li key={exam.examId}>
                {exam.examName} ({exam.examDuration} dk)
              </li>
            ))}
          </ul>
        ),
      },
      {
        title: 'Toplam Süre',
        key: 'totalDuration',
        render: (_, record) => 
          `${record.exams.reduce((sum, exam) => sum + exam.examDuration, 0)} dk`,
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.examPurchases}
        pagination={false}
        rowKey="id"
      />
    );
  };

  const columns = [
    {
      title: 'Kullanıcı Adı',
      key: 'userName',
      render: (_, record) => `${record.userName} ${record.userSurname}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'userEmail',
      key: 'userEmail',
    },
    {
      title: 'Toplam Alım',
      key: 'totalPurchases',
      render: (_, record) => record.examPurchases.length,
    },
    {
      title: 'Toplam Sınav',
      key: 'totalExams',
      render: (_, record) => 
        record.examPurchases.reduce((sum, purchase) => sum + purchase.exams.length, 0),
    },
    {
      title: 'Toplam Süre',
      key: 'totalDuration',
      render: (_, record) => 
        `${record.examPurchases.reduce((sum, purchase) => 
          sum + purchase.exams.reduce((examSum, exam) => examSum + exam.examDuration, 0), 0
        )} dk`,
    }
  ];

  return (
    <MainCard title="Token Harcama Geçmişi">
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Kullanıcı"
                value={statistics.totalUsers}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Alım"
                value={statistics.totalPurchases}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Sınav"
                value={statistics.totalExams}
                prefix={<BookOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Toplam Süre"
                value={`${statistics.totalDuration} dk`}
                prefix={<FieldTimeOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <Table
          columns={columns}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: true,
          }}
          dataSource={examPurchases}
          rowKey="userId"
          loading={loading}
          pagination={{
            total: examPurchases.length,
            pageSize: 10,
            showTotal: (total) => `Toplam ${total} kullanıcı`,
            showSizeChanger: true,
          }}
        />
      </Space>
    </MainCard>
  );
};

export default TokenUsage; 