import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth";
import identitySlice from "./slices/identity";
import discountReducer from './slices/discount';
import userReducer from './slices/user';
import examReducer from './slices/exam';
import tokenReducer from './slices/token';

export default configureStore({
  reducer: {
    identity: identitySlice,
    auth: authSlice,
    discount: discountReducer,
    user: userReducer,
    exam: examReducer,
    token: tokenReducer
  }
});
